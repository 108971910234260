import S from './Login.module.scss';
import { AuthUtils } from '../../../utils/AuthUtils';
import { useEffect, useState } from 'react'
import _ from 'lodash';
import { checkVerifyToken, fetchUsersMe, generalLogin } from '../../../repository/LoginRepository'
import { Link } from 'react-router-dom';
import googleLogo from './assets/google-logo.svg';
import awakeLogo from './assets/awake-logo.svg';
import { fetchMetaAccounts } from '../../../repository/MetaDataRepository'
import { AuthorizationTokenAtom, LoginFailureFlagAtom, MyInfoAtom } from '../../../recoil/Authorization.recoil'
import { MetaAccountsAtom, TikTokAccountsAtom } from '../../../recoil/Data.recoil'
import { useRecoilState } from 'recoil'
import { fetchTikTokAccounts } from '../../../repository/TikTokDataRepository'

const Login = () => {
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [token, setToken] = useRecoilState(AuthorizationTokenAtom);
  const [myInfo, setMyInfo] = useRecoilState(MyInfoAtom);
  const [metaAccounts, setMetaAccounts] = useRecoilState(MetaAccountsAtom);
  const [tikTokAccounts, setTikTokAccounts] = useRecoilState(TikTokAccountsAtom);
  const [loginFailed, setLoginFailed] = useRecoilState(LoginFailureFlagAtom);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const basicInfoSetting = (token: string) => {
    fetchUsersMe(token)
      .then((res) => {
        if (res.status === 'NORMAL') {
          setMyInfo(res);
          setLoginFailed(false);
        } else {
          AuthUtils.removeCookie('token');
          setLoginFailed(true);
          alert('인증 에러발생');
        }
      })
      .catch((error) => {
        AuthUtils.removeCookie('token');
        setLoginFailed(true);
        alert('인증 에러발생');
      });
    fetchMetaAccounts(token)
      .then((res) => {
        setMetaAccounts(res);
      })
      .catch((error) => console.error(error));
    fetchTikTokAccounts(token)
      .then((res) => {
        setTikTokAccounts(res);
      })
      .catch((error) => console.error(error));
  }

  // 이미 로그인 되어있으면 홈으로 redirect
  useEffect(() => {
    const token = AuthUtils.getCookie('token');
    if (!_.isEmpty(token)) {
      checkVerifyToken(token)
        .then((res) => {
          if (res.id > 0) {
            basicInfoSetting(token);
            location.href = '/data/meta/sets';
          }
        })
        .catch((error) => console.error(error));
    }
  }, []);

  const handleGeneralLogin = () => {
    generalLogin(id, password)
      .then((res) => {
        // 서버가 리다이렉트를 처리하기 때문에 클라이언트에서 별도의 리다이렉트 처리는 필요 없습니다.
        if (!_.isEmpty(res.accessToken)) {
          // 로그인 성공 시 추가적인 처리가 필요하다면 여기에 작성
          location.href = `/auth?authenticated=true&accessToken=${res.accessToken}`
          setLoginFailed(false);
        } else {
          // 서버가 예상치 못한 응답을 반환한 경우 처리
          setLoginFailed(true);
          alert('인증 에러 발생');
        }
      })
      .catch((error) => {
        // 요청 실패 처리
        console.error('Login failed:', error);
        setLoginFailed(true);
      });
  };

  return (
    <main className={S.login_container}>
      <section className="wrapper">
        <div className={S.logo_wrap}>
          <h1 className={S.logo}>
            <Link to="/login">AWAKE</Link>
          </h1>

          {loginFailed && (
            <div className={S.error_content}>
              <strong className={S.error_title}>Authentication Error</strong>
              <p className={S.error_message}>
                권한이 없는 메일입니다.
                관리자에게 연락 주세요.
              </p>
            </div>
          )}
        </div>
        <div className={S.sns_login_wrap}>
          <div
            className={`${S.sns_btn} ${showLoginForm ? S.active : ''}`}
            onClick={() => setShowLoginForm(!showLoginForm)}
          >
            <p className="button-l">Login</p>
          </div>

          {showLoginForm && (
            <div className={S.login_form}>
              <input
                type="text"
                placeholder="ID"
                value={id}
                onChange={(e) => setId(e.target.value)}
                className={S.input_field}
              />
              <input
                type="password"
                placeholder="PASSWORD"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={S.input_field}
              />
              <button
                className={S.login_btn}
                onClick={handleGeneralLogin}
              >
                Login with ID and Password
              </button>
            </div>
          )}

          <div
            className={S.sns_btn}
            onClick={() => {
              location.href = `${apiDomain}/oauth2/authorization/google${window.location.hostname === 'localhost' ? '?redirectUrl=http://localhost:3000' : ''}`
            }}
          >
            <img src={googleLogo} alt="Google Login" />
            <p className="button-l">Google Login</p>
          </div>
        </div>
      </section>
    </main>
  )
};

export default Login;
