import React, { useEffect, useState } from 'react';
import S from './AdCreativePopup.module.scss';
import { fetchAdHistories, fetchMetaAdDetails } from '../../../../../repository/MetaDataRepository'
import { AuthorizationTokenAtom } from '../../../../../recoil/Authorization.recoil';
import { useRecoilState, useRecoilValue } from 'recoil'
import { AdHistoryType, MetaAdDetailType, MetaAdInsightType } from '../../../../../types/MetaDataTypes'
import { MetaAccountsAtom } from '../../../../../recoil/Data.recoil'
import dayjs from 'dayjs'

interface AdCreativePopupProps {
  adId: string;
  from: string;
  to: string;
  onClose: (type: 'SETS' | 'AD') => void;
}

const AdCreativePopup: React.FC<AdCreativePopupProps> = ({ adId, from, to, onClose }) => {
  const token = useRecoilValue(AuthorizationTokenAtom);
  const [adDetail, setAdDetail] = useState<MetaAdDetailType | null>(null);
  const [adHistories, setAdHistories] = useState<AdHistoryType[]>([]);
  const [metaAccounts, setMetaAccounts] = useRecoilState(MetaAccountsAtom);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    fetchMetaAdDetails(token, adId, from, to).then((data) => {
      setAdDetail(data);
    });

    fetchAdHistories(token, metaAccounts[0].metaAccountId, adId).then((data) => {
      const sortedHistories = data.content.sort((a, b) =>
        new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
      );
      setAdHistories(sortedHistories);
    });

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [adId]);

  if (!adDetail) {
    return (
      <div className={S.popupOverlay}>
        <div className={S.popupContent}>
          <div className={S.popupHeader}>
            <button className={S.closeButton} onClick={() => onClose('AD')}>
              X
            </button>
            <h2>Details</h2>
          </div>
          <div>
            {'Loading...'}
          </div>
        </div>
      </div>
    )
  }

  // 사용할 필드 목록
  const fields = ['spend', 'impressions', 'ctr', 'cpc', 'cpm', 'roas'];

  return (
    <div className={S.popupOverlay}>
      <div className={S.popupContent}>
        <div className={S.popupHeader}>
          <button className={S.closeButton} onClick={() => onClose('AD')}>
            X
          </button>
          <h2>Details</h2>
        </div>

        <div className={S.contentWrapper}>
          {/* 소재 영역 */}
          <div className={S.materialSection}>
            <h4>광고 소재</h4>
            {adDetail.adObject && <div className={S.adBody}>{adDetail.adObject.body}</div>}
            {adDetail.adObject && adDetail.adObject.adLink && (
              <a
                href={adDetail.adObject.adLink}
                target="_blank"
                rel="noopener noreferrer"
                className={S.adLink}
              >
                {adDetail.adObject.adLink}
              </a>
            )}
            <div className={S.imageWrapper}>
              {adDetail.adObject && adDetail.adObject.imageUrl ? (
                <img
                  src={adDetail.adObject.imageUrl}
                  alt="광고 이미지"
                  className={S.adImage}
                />
              ) : (
                <div className={S.noImage}>이미지가 없습니다</div>
              )}
            </div>
          </div>

          {/* 데이터 테이블 영역 */}
          <div className={S.tableSection}>
            <h3>최근 7일 data insights</h3>
            <table className={S.dataTable}>
              <thead>
                <tr>
                  <th>Date</th>
                  {fields.map((field) => (
                    <th key={field}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {adDetail.insights.map((insight) => (
                  <tr key={insight.date}>
                    <td>{insight.date}</td>
                    {fields.map((field) => (
                      <td key={field}>
                        {insight[field as keyof MetaAdInsightType] !== null
                          ? insight[field as keyof MetaAdInsightType]
                          : ''}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {adHistories.length > 0 && (
            <div className={S.historySection}>
              <h3>광고 제어 히스토리</h3>
              <table className={S.dataTable}>
                <thead>
                <tr>
                  <th>날짜</th>
                  <th>제어 유형</th>
                  <th>User</th>
                  <th>Memo</th>
                </tr>
                </thead>
                <tbody>
                {adHistories.map((history) => (
                  <tr key={history.id}>
                    <td>{dayjs(history.updatedAt).format('YYYY.MM.DD HH:mm')}</td>
                    <td>{history.description}</td>
                    <td>{history.userEmail}</td>
                    <td>{history.memo}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          )}

        </div>
      </div>
    </div>
  )
};

export default AdCreativePopup;
