import React, { useEffect, useState } from 'react'
import S from './CommentPopup.module.scss'
import { AuthorizationTokenAtom } from '../../../../../recoil/Authorization.recoil'
import { useRecoilValue } from 'recoil'
import { updateMemo } from '../../../../../repository/TikTokDataRepository' // 스타일 파일 가져오기

interface CommentPopupProps {
  adId: string
  initialComment: string
  onClose: (type: 'EDIT' | 'CANCEL') => void
}

const CommentPopup = (props: CommentPopupProps) => {
  const { adId, initialComment, onClose } = props
  const token = useRecoilValue(AuthorizationTokenAtom)
  const [comment, setComment] = useState(initialComment)

  console.log('comment : ' + comment)

  useEffect(() => {
    setComment(initialComment)
  }, [initialComment])

  const handleSave = () => {
    // 코멘트 저장 로직 추가
    updateMemo(token, adId, comment)
      .then((res) => {})
      .finally(() => onClose('EDIT'))
  }


  return (
    <div className={S.popupOverlay}>
      <div className={S.popupContent}>
        <h3>코멘트 수정</h3>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className={S.popupActions}>
          <button onClick={handleSave}>저장</button>
          <button onClick={() => onClose('CANCEL')}>취소</button>
        </div>
      </div>
    </div>
  )
}

export default CommentPopup
