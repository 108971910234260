import React, { useEffect, useState } from 'react'
import { FaSyncAlt } from 'react-icons/fa'
import Switch from 'react-switch'
import 'react-datepicker/dist/react-datepicker.css'
import S from './MetaAdsData.module.scss'
import {
  AD_STATUS,
  MetaAdType,
  MetaDataFilterType,
  MetaStatusEditType,
  SortingType,
} from '../../../../../types/MetaDataTypes'
import { UserInfoService } from '../../../../../service/UserInfoService'
import {
  AuthorizationTokenAtom,
  MyInfoAtom,
} from '../../../../../recoil/Authorization.recoil'
import { useRecoilState, useRecoilValue } from 'recoil'
import _ from 'lodash'
import MetaDataSearch from '../MetaDataSearch'
import {
  changeAdStatus, fetchMetaAccounts,
  fetchMetaAdsData,
  metaBulkChangeAdStatus, syncDataManually,
} from '../../../../../repository/MetaDataRepository'
import dayjs from 'dayjs'
import { MetaAccountsAtom } from '../../../../../recoil/Data.recoil'
import AdCreativePopup from '../AdCreativePopup'
import CommentPopup from '../CommentPopup'

const MetaAdsData = () => {
  UserInfoService()

  const token = useRecoilValue(AuthorizationTokenAtom)
  const myInfo = useRecoilValue(MyInfoAtom)
  const [metaAccounts, setMetaAccounts] = useRecoilState(MetaAccountsAtom);
  const [data, setData] = useState<MetaAdType[]>([])
  const [filter, setFilters] = useState<MetaDataFilterType>(
    {} as MetaDataFilterType,
  )
  const [totalElements, setTotalElements] = useState(0)
  const [bulkStatus, setBulkStatus] = useState<AD_STATUS>('') // 일괄 ON/OFF 조정을 위한 상태 추가
  const [sortConfig, setSortConfig] = useState({
    key: 'spend',
    direction: 'asc',
  } as SortingType)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [popupUsedAdId, setPopupUsedId] = useState<string>('')
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [commentPopupData, setCommentPopupData] = useState<{adId: string, comment: string} | null>(null)

  useEffect(() => {
    const ids = metaAccounts?.map((it) => it.metaAccountId)
    if (!_.isEmpty(token) && ids?.length > 0) {
      const campaignIds = metaAccounts[0].campaigns?.map((it) => it.campaignId)
      if (campaignIds?.length > 0) {
        const initFilters = {
          metaAccountId: ids[0],
          campaignId: campaignIds[0],
          setId: '',
          setName: '',
          adId: '',
          adName: '',
          startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        } as MetaDataFilterType
        setFilters((prev) => initFilters)
        fetchData(initFilters)
      }
    }
  }, [myInfo, metaAccounts])

  // 코멘트 수정 버튼 클릭 시 호출되는 함수
  const handleOpenCommentPopup = (adId: string, comment: string) => {
    setCommentPopupData({ adId, comment }); // 코멘트 팝업 열기
  };

  const handleCloseCommentPopup = (type: 'EDIT' | 'CANCEL') => {
    setCommentPopupData(null);
    if(type == 'EDIT'){
      fetchData(filter)
    }
  };

  const handleSyncClick = () => {
    if(myInfo.adAccount.tier !== 'PREMIUM'){
      alert('프리미엄 티어 이상부터 사용가능 합니다.')
      return
    }

    setIsSyncing(true) // 동기화 중 상태 true
    syncDataManually(token, filter.startDate, filter.endDate)
      .then((res) => {
        if(res.success){
          alert('동기화 성공!')
          fetchMetaAccounts(token)
            .then((res) => {
              setMetaAccounts(res);
            })
            .catch((error) => console.error(error));
        }else{
            alert(`동기화 실패. \n${res.message}`)
        }
      })
      .catch((e) => {
        // 실패 시 처리
        console.error(e)
        alert('동기화 실패. \n관리자에게 문의하세요.')
      })
      .finally(() => {
        setIsSyncing(false) // 동기화 중 상태 false
      })
  }

  const getLatestSyncTime = () => {
    const successfulSyncs = metaAccounts[0]?.latestSyncHistories?.filter(
      (history) => history.status === 'SUCCESS',
    )
    if (successfulSyncs?.length > 0) {
      const latestSync = _.maxBy(successfulSyncs, (sync) =>
        dayjs(sync.updatedAt).valueOf(),
      )
      return latestSync?.updatedAt
        ? dayjs(latestSync.updatedAt).format('YYYY-MM-DD HH:mm:ss')
        : null
    }
    return null
  }

  const latestSyncTime = getLatestSyncTime()
  const isSyncSuccessful = latestSyncTime !== null

  const handleCheckboxChange = (adId: string) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(adId)
        ? prevSelected.filter((id) => id !== adId)
        : [...prevSelected, adId],
    );
  };

  // 전체 선택 체크박스 핸들러
  const handleSelectAll = () => {
    if (selectedRows.length === sortedData.length) {
      setSelectedRows([]) // 모두 선택된 상태에서 다시 클릭하면 모두 해제
    } else {
      setSelectedRows(sortedData.map((row) => row.setId)) // 전체 선택
    }
  }

  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      return [...data].sort((a, b) => {
        const key = sortConfig.key as keyof MetaAdType // 'key'의 타입을 명확히 지정
        if (a[key] < b[key]) {
          return sortConfig.direction === 'asc' ? -1 : 1
        }
        if (a[key] > b[key]) {
          return sortConfig.direction === 'asc' ? 1 : -1
        }
        return 0
      })
    }
    return data
  }, [data, sortConfig])

  const requestSort = (key: string) => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  const convertStatusOpposite = (status: string): AD_STATUS => {
    return status === 'ACTIVE' ? 'PAUSED' : 'ACTIVE'
  }

  const handleAdSwitchChange = (
    adId: string,
    adName: string,
    status: AD_STATUS,
  ) => {
    const action = status === 'ACTIVE' ? 'off' : 'on';
    const message = `해당 광고 ${action} 하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');

    if (memo != null) {
      setIsLoading(true);
      changeAdStatus(token, adId, convertStatusOpposite(status), memo)
        .then((res) => {
          if (res.success) {
            alert(`[AD] ${adName} ${action === 'on' ? 'ON 성공' : 'OFF 성공'}`);
            setSelectedRows([]); // 체크박스 해제
            setBulkStatus(''); // Bulk 상태 초기화
            handleSearch(filter);
          } else {
            alert(
              `[AD] ${adName} ${action === 'on' ? 'ON 실패' : 'OFF 실패'}\n 관리자 문의 부탁드립니다.`,
            );
          }
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  };

  const fetchData = (filter: MetaDataFilterType) => {
    setIsLoading(true)
    const queryParams = [
      `metaAccountId=${filter.metaAccountId}`,
      filter.campaignId ? `campaignId=${filter.campaignId}` : '',
      filter.setId ? `setId=${filter.setId}` : '',
      filter.setName ? `setName=${filter.setName}` : '',
      filter.setStatus ? `setStatus=${filter.setStatus}` : '',
      filter.adId ? `adId=${filter.adId}` : '',
      filter.adName ? `adName=${filter.adName}` : '',
      filter.adStatus ? `adStatus=${filter.adStatus}` : '',
      filter.goeSpend !== undefined ? `goeSpend=${filter.goeSpend}` : '',
      filter.loeSpend !== undefined ? `loeSpend=${filter.loeSpend}` : '',
      filter.goeRoas !== undefined ? `goeRoas=${filter.goeRoas}` : '',
      filter.loeRoas !== undefined ? `loeRoas=${filter.loeRoas}` : '',
      `from=${filter.startDate}`,
      `to=${filter.endDate}`,
    ]
      .filter(Boolean) // 빈 문자열을 제거합니다.
      .join('&') // '&'로 쿼리 파라미터를 연결합니다.

    fetchMetaAdsData(token, queryParams)
      .then((res) => {
        setData(res)
        setTotalElements(res.length)
      })
      .catch((e) => {
        console.error(e.message)
        alert('데이터 조회 실패!')
      })
      .finally(() => {
        setIsLoading(false) // 로딩 끝
      })
  }

  const handleBulkStatusChange = () => {
    if (selectedRows.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }

    const action = bulkStatus === 'ACTIVE' ? '활성화' : '비활성화';
    const message = `선택된 광고를 ${action} 하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');

    if (memo != null) { // memo 입력창에서 취소 버튼을 누른 경우를 제외하고 처리
      setIsLoading(true);
      const requestPayload = data
        .filter((item) => selectedRows.includes(item.adId))
        .map(
          (item) =>
            ({
              id: item.adId,
              status: bulkStatus,
              memo: memo, // memo를 각 요청에 포함
            } as MetaStatusEditType),
        );

      metaBulkChangeAdStatus(token, requestPayload)
        .then((responses) => {
          const failedIds = responses
            .filter((res) => !res.success)
            .map((res) => res.id);

          if (failedIds.length === 0) {
            alert('일괄 상태 변경 성공!');
          } else {
            alert(
              `일괄 상태 변경 실패! 실패한 ID: ${failedIds.join(', ')}\n 관리자에게 문의해주세요.`,
            );
          }

          setSelectedRows([]); // 체크박스 해제
          setBulkStatus(''); // Bulk 상태 초기화
          handleSearch(filter);
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  };

  const handleSearch = (filter: MetaDataFilterType) => {
    fetchData(filter)
  }

  const handleToggleClick = (e: React.MouseEvent) => {
    e.stopPropagation() // Row 클릭 이벤트로부터 이벤트 버블링을 막습니다.
  }

  const handleOpenPopup = (adId: string) => {
    setPopupUsedId(adId) // adId만 저장
    setIsPopupOpen(true) // 팝업 열기
  }

  const handleClosePopup = () => {
    setIsPopupOpen(false) // 팝업 닫기
  }

  if (metaAccounts.length === 0) {
    return <></>
  }

  return (
    <div className={S.container}>
      <div className={S.pageHeader}>
        <h1>Meta 광고 관리</h1>
        <p className={S.pageDescription}>광고 데이터를 관리합니다</p>
      </div>

      <div className={S.searchSection}>
        <MetaDataSearch
          metaAccounts={metaAccounts || []}
          filters={filter}
          setFilters={setFilters}
          handleSearch={handleSearch}
          searchType={'AD'}
        />
      </div>

      {totalElements > 0 && (
        <div className={S.uploadSection}>
          <div className={S.bulkControls}>
            <div className={S.bulkGroup}>
              <div className={S.bulkItem}>
                <label>광고 상태 일괄 변경</label>
                <div className={S.buttonGroup}>
                  <button
                    onClick={() => setBulkStatus('ACTIVE')}
                    className={`${S.statusButton} ${bulkStatus === 'ACTIVE' ? S.active : ''}`}
                  >
                    전체 ON
                  </button>
                  <button
                    onClick={() => setBulkStatus('PAUSED')}
                    className={`${S.statusButton} ${bulkStatus === 'PAUSED' ? S.active : ''}`}
                  >
                    전체 OFF
                  </button>
                  <button 
                    onClick={handleBulkStatusChange} 
                    className={S.applyButton}
                    disabled={!bulkStatus || selectedRows.length === 0}
                  >
                    적용
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={S.dataSection}>
        <div className={S.dataInfo}>
          <div className={S.totalCount}>
            총 {totalElements.toLocaleString()}개의 데이터
          </div>
          <div className={S.syncInfo}>
            <div className={S.syncStatus}>
              <span className={isSyncSuccessful ? S.syncSuccess : S.syncError}>
                {isSyncSuccessful ? '동기화 완료' : '동기화 필요'}
              </span>
              {isSyncSuccessful && (
                <span className={S.syncTime}>최근 동기화: {latestSyncTime}</span>
              )}
            </div>
            <button
              className={`${S.syncButton} ${isSyncing ? S.syncing : ''}`}
              onClick={handleSyncClick}
              disabled={isSyncing}
            >
              {isSyncing ? <FaSyncAlt className={S.syncIcon} /> : '수동 동기화'}
            </button>
          </div>
        </div>

        {isLoading ? (
          <div className={S.loadingWrapper}>
            <div className={S.loader}></div>
            <span>데이터를 불러오는 중...</span>
          </div>
        ) : totalElements === 0 ? (
          <div className={S.noData}>데이터가 없습니다.</div>
        ) : (
          <div className={S.tableWrapper}>
            <table className={S.table}>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectedRows.length === sortedData.length}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th>광고 ON/OFF</th>
                  <th>캠페인 명</th>
                  <th>광고 세트명</th>
                  <th>광고명</th>
                  <th>소재 및 Insights</th>
                  <th>메모</th>
                  <th onClick={() => requestSort('spend')}>
                    지출 {sortConfig.key === 'spend' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : null}
                  </th>
                  <th>매출</th>
                  <th>ROAS</th>
                  <th>노출</th>
                  <th>CTR</th>
                  <th>클릭 수</th>
                  <th>CPC</th>
                  <th>CPM</th>
                  <th>구매당 매출</th>
                  <th>전환율</th>
                </tr>
              </thead>
              <tbody>
                {sortedData?.map((row) => (
                  <tr key={`${row.campaignId}-${row.setId}-${row.adId}`}>
                    <td className={S.checkboxCell}>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(row.adId)}
                        onChange={() => handleCheckboxChange(row.adId)}
                        onClick={handleToggleClick}
                      />
                    </td>
                    <td className={S.switchCell}>
                      <Switch
                        checked={row.status === 'ACTIVE'}
                        onChange={() => handleAdSwitchChange(row.adId, row.name, row.status)}
                        onColor="#2e7d32"
                        offColor="#e0e0e0"
                        onHandleColor="#ffffff"
                        offHandleColor="#ffffff"
                        handleDiameter={24}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={16}
                        width={36}
                        className="react-switch"
                      />
                    </td>
                    <td>{row.campaignName}</td>
                    <td>{row.setName}</td>
                    <td>{row.name}</td>
                    <td>
                      <button
                        className={S.insightButton}
                        onClick={() => handleOpenPopup(row.adId)}
                      >
                        자세히 보기
                      </button>
                    </td>
                    <td>
                      <button
                        className={S.commentButton}
                        onClick={() => handleOpenCommentPopup(row.adId, row.memo)}
                      >
                        메모 확인
                      </button>
                    </td>
                    <td>{row.spend?.toLocaleString()}</td>
                    <td>{row.revenue?.toLocaleString()}</td>
                    <td>{row.roas}</td>
                    <td>{row.impressions?.toLocaleString()}</td>
                    <td>{row.ctr?.toLocaleString()}</td>
                    <td>{row.clicks?.toLocaleString()}</td>
                    <td>{row.cpc?.toLocaleString()}</td>
                    <td>{row.cpm?.toLocaleString()}</td>
                    <td>{row.revenuePerPurchase?.toLocaleString()}</td>
                    <td>{row.purchaseRatio}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {isPopupOpen && !_.isEmpty(popupUsedAdId) && (
        <AdCreativePopup
          adId={popupUsedAdId}
          from={dayjs(filter?.endDate).subtract(6, 'day').format('YYYY-MM-DD')}
          to={filter?.endDate}
          onClose={handleClosePopup}
        />
      )}

      {commentPopupData && (
        <CommentPopup
          adId={commentPopupData.adId}
          initialComment={commentPopupData.comment}
          onClose={handleCloseCommentPopup}
        />
      )}
    </div>
  )
}

export default MetaAdsData
