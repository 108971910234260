import React, { useEffect, useState } from 'react'
import S from './MyAccount.module.scss';
import { UserInfoService } from '../../../../service/UserInfoService';
import { AuthorizationTokenAtom, MyInfoAtom } from '../../../../recoil/Authorization.recoil';
import { useRecoilValue } from 'recoil';
import { MetaAdAccountType, TikTokAdAccountType } from '../../../../types/LoginTypes'
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import { SiMeta, SiTiktok } from 'react-icons/si';

const MyAccount = () => {
  UserInfoService();
  const token = useRecoilValue(AuthorizationTokenAtom);
  const myInfo = useRecoilValue(MyInfoAtom);
  const [metaAdAccounts, setMetaAdAccounts] = useState<MetaAdAccountType[]>([]);
  const [tikTokAdAccounts, setTikTokAdAccounts] = useState<TikTokAdAccountType[]>([]);
  const [isMetaAdAccountsVisible, setMetaAdAccountsVisible] = useState(true);
  const [isTikTokAdAccountsVisible, setTikTokAdAccountsVisible] = useState(true);

  useEffect(() => {
    if(myInfo?.adAccount?.metaAdAccounts) setMetaAdAccounts(myInfo.adAccount.metaAdAccounts);
    if(myInfo?.adAccount?.tikTokAdAccounts) setTikTokAdAccounts(myInfo.adAccount.tikTokAdAccounts);
  }, [myInfo, token]);

  if (!myInfo.id) return <div className={S.loading}>Loading...</div>;

  return (
    <div className={S.container}>
      <div className={S.section}>
        <h2 className={S.sectionTitle}>계정 정보</h2>
        <div className={S.card}>
          <div className={S.field}>
            <label>이메일</label>
            <input type="text" value={myInfo.email} readOnly />
          </div>
          <div className={S.field}>
            <label>계정 유형</label>
            <input type="text" value={myInfo.type} readOnly />
          </div>
        </div>
      </div>

      <div className={S.section}>
        <h2 className={S.sectionTitle}>광고 계정 정보</h2>
        <div className={S.card}>
          <div className={S.field}>
            <label>계정명</label>
            <input type="text" value={myInfo.adAccount.name} readOnly />
          </div>
          <div className={S.field}>
            <label>설명</label>
            <input type="text" value={myInfo.adAccount.description} readOnly />
          </div>
          <div className={S.field}>
            <label>등급</label>
            <input type="text" value={myInfo.adAccount.tier} readOnly />
          </div>

          <div className={S.platformSection}>
            <div className={S.platformHeader} onClick={() => setMetaAdAccountsVisible(!isMetaAdAccountsVisible)}>
              <div className={S.platformTitle}>
                <SiMeta className={S.platformIcon} />
                Meta 광고 계정
              </div>
              {isMetaAdAccountsVisible ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
            </div>
            {isMetaAdAccountsVisible && (
              <div className={S.accountsList}>
                {metaAdAccounts.map((account) => (
                  <div key={account.metaAccountId} className={S.accountCard}>
                    <div className={S.field}>
                      <label>계정명</label>
                      <input type="text" value={account.name} readOnly />
                    </div>
                    <div className={S.field}>
                      <label>설명</label>
                      <input type="text" value={account.description} readOnly />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={S.platformSection}>
            <div className={S.platformHeader} onClick={() => setTikTokAdAccountsVisible(!isTikTokAdAccountsVisible)}>
              <div className={S.platformTitle}>
                <SiTiktok className={S.platformIcon} />
                TikTok 광고 계정
              </div>
              {isTikTokAdAccountsVisible ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
            </div>
            {isTikTokAdAccountsVisible && (
              <div className={S.accountsList}>
                {tikTokAdAccounts.map((account) => (
                  <div key={account.advertiserId} className={S.accountCard}>
                    <div className={S.field}>
                      <label>계정명</label>
                      <input type="text" value={account.name} readOnly />
                    </div>
                    <div className={S.field}>
                      <label>설명</label>
                      <input type="text" value={account.description} readOnly />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
};

export default MyAccount;