import React, { useEffect, useMemo, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil'
import _ from 'lodash'
import { ClipLoader } from 'react-spinners'
import { Cafe24ContentsType } from '../../../../types/MetaDataTypes'
import { fetchRawData, uploadCafe24Excel } from '../../../../repository/MetaDataRepository'
import { UserInfoService } from '../../../../service/UserInfoService'
import { AuthorizationTokenAtom } from '../../../../recoil/Authorization.recoil'
import S from './RawData.module.scss'
import 'react-datepicker/dist/react-datepicker.css'

const columns: Column<Cafe24ContentsType>[] = [
  { Header: '광고매체', accessor: 'adMedia' },
  { Header: '날짜', accessor: 'date' },
  { Header: '방문수', accessor: 'visitCount' },
  { Header: '방문비율', accessor: 'visitRatio' },
  { Header: '구매건수', accessor: 'purchaseCount' },
  { Header: '구매율', accessor: 'purchaseRatio' },
  { Header: '매출액', accessor: 'revenue' },
  { Header: '구매당 매출', accessor: 'revenuePerPurchase' },
  { Header: '방문당 매출', accessor: 'revenuePerVisit' },
]

const RawData: React.FC = () => {
  UserInfoService()
  const token = useRecoilValue(AuthorizationTokenAtom)
  
  // States
  const [data, setData] = useState<Cafe24ContentsType[]>([])
  const [startDate, setStartDate] = useState<Date>(dayjs().subtract(1, 'day').toDate())
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate())
  const [uploadDate, setUploadDate] = useState<Date>(dayjs().toDate())
  const [mediaCode, setMediaCode] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(30)
  const [totalElements, setTotalElements] = useState(0)
  const [isLast, setIsLast] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!_.isEmpty(token)) {
      fetchData()
    }
  }, [currentPage, pageSize])

  const fetchData = () => {
    setIsLoading(true)
    fetchRawData(token, mediaCode, dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'), currentPage, pageSize)
      .then((res) => {
        if (res && res.content) {
          setData(res.content)
          setCurrentPage(res.pageNumber)
          setPageSize(res.pageSize)
          setTotalElements(res.totalElements)
          setIsLast(res.isLast)
          setTotalPages(res.totalPages)
          setTablePageSize(pageSize)
        } else {
          setData([])
          setTotalElements(0)
          setTotalPages(0)
          setIsLast(true)
        }
      })
      .catch((e) => {
        console.error(e.message)
        alert('데이터 조회 실패!')
        setData([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert('파일을 선택해주세요.')
      return
    }

    try {
      await uploadCafe24Excel(token, selectedFile, mediaCode)
      alert('파일 업로드 성공!')
      fetchData()
    } catch (error) {
      console.error('File upload error:', error)
      alert('파일 업로드 실패!')
    }
  }

  const handleSearch = () => {
    setCurrentPage(0)
    fetchData()
  }

  // Table Configuration
  const tableInstance = useTable(
    {
      columns: useMemo(() => columns, []),
      data: data || [],
      initialState: { pageIndex: 0, pageSize },
    },
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize: setTablePageSize,
  } = tableInstance

  return (
    <div className={S.container}>
      <header className={S.pageHeader}>
        <h1>마케팅 데이터 분석</h1>
        <p className={S.pageDescription}>
          마케팅 채널별 실적 데이터를 관리하고 분석합니다.
        </p>
      </header>

      <section className={S.uploadSection}>
        <div className={S.uploadGroup}>
          <div className={S.uploadItem}>
            <label htmlFor="fileUpload">데이터 파일</label>
            <div className={S.fileInputWrapper}>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                id="fileUpload"
              />
            </div>
          </div>
          <div className={S.uploadDateItem}>
            <label htmlFor="uploadDate">데이터 기준일</label>
            <DatePicker
              id="uploadDate"
              selected={uploadDate}
              onChange={(date) => date && setUploadDate(date)}
              dateFormat="yyyy-MM-dd"
              className={S.customDatePicker}
            />
          </div>
          <div className={S.uploadButtonContainer}>
            <button onClick={handleFileUpload} className={S.uploadBtn}>
              업로드
            </button>
          </div>
        </div>
      </section>

      <section className={S.searchSection}>
        <div className={S.searchControls}>
          <div className={S.datePicker}>
            <div className={S.datePickerItem}>
              <label htmlFor="startDate">시작일</label>
              <DatePicker
                id="startDate"
                selected={startDate}
                onChange={(date) => date && setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                className={S.customDatePicker}
              />
            </div>
            <div className={S.datePickerItem}>
              <label htmlFor="endDate">종료일</label>
              <DatePicker
                id="endDate"
                selected={endDate}
                onChange={(date) => date && setEndDate(date)}
                dateFormat="yyyy-MM-dd"
                className={S.customDatePicker}
              />
            </div>
          </div>
          <div className={S.mediaFilter}>
            <label htmlFor="mediaCode">매체 구분</label>
            <input
              type="text"
              id="mediaCode"
              value={mediaCode}
              onChange={(e) => setMediaCode(e.target.value)}
              className={S.searchInput}
              placeholder="매체 코드 입력"
            />
          </div>
          <div className={S.searchButtonContainer}>
            <button onClick={handleSearch} className={S.searchBtn}>
              조회
            </button>
          </div>
        </div>
      </section>

      <section className={S.dataSection}>
        <div className={S.dataInfo}>
          <span className={S.totalCount}>
            총 {totalElements.toLocaleString()}건
          </span>
          {isLoading && (
            <div className={S.loadingWrapper}>
              <ClipLoader size={20} color="#1a237e" />
            </div>
          )}
        </div>
        <div className={S.tableWrapper}>
          <table {...getTableProps()} className={S.table}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className={S.pagination}>
          <div className={S.paginationControls}>
            <button
              onClick={() => gotoPage(0)}
              disabled={currentPage === 0}
              className={S.paginationButton}
            >
              처음
            </button>
            <button
              onClick={() => setCurrentPage(Math.max(0, currentPage - 1))}
              disabled={currentPage === 0}
              className={S.paginationButton}
            >
              이전
            </button>
            <span className={S.pageInfo}>
              {currentPage + 1} / {totalPages} 페이지
            </span>
            <button
              onClick={() => setCurrentPage(Math.min(totalPages - 1, currentPage + 1))}
              disabled={isLast}
              className={S.paginationButton}
            >
              다음
            </button>
            <button
              onClick={() => gotoPage(totalPages - 1)}
              disabled={isLast}
              className={S.paginationButton}
            >
              마지막
            </button>
          </div>
          <div className={S.pageJump}>
            <span>페이지 이동</span>
            <input
              type="number"
              min={1}
              max={totalPages}
              defaultValue={currentPage + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              className={S.pageJumpInput}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default RawData