import {atom} from "recoil";
import { MetaAdAccountType, MyInfoType, TikTokAdAccountType } from '../types/LoginTypes'

export const MetaAccountsAtom = atom<MetaAdAccountType[]>({
    key: 'awake.meta.accounts',
    default: [] as MetaAdAccountType[]
})

export const TikTokAccountsAtom = atom<TikTokAdAccountType[]>({
    key: 'awake.tiktok.accounts',
    default: [] as TikTokAdAccountType[]
})

