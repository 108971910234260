import React, { useEffect, useState } from 'react'
import { FaSyncAlt } from 'react-icons/fa'
import Switch from 'react-switch'
import 'react-datepicker/dist/react-datepicker.css'
import S from './TikTokAdsData.module.scss'
import {
  AD_STATUS,
  TikTokAdType,
  TikTokDataFilterType,
  TikTokStatusEditType,
  SortingType,
} from '../../../../../types/TikTokDataTypes'
import { UserInfoService } from '../../../../../service/UserInfoService'
import {
  AuthorizationTokenAtom,
  MyInfoAtom,
} from '../../../../../recoil/Authorization.recoil'
import { useRecoilState, useRecoilValue } from 'recoil'
import _ from 'lodash'
import TikTokDataSearch from '../TikTokDataSearch'
import {
  changeAdStatus, fetchTikTokAccounts,
  fetchTikTokAdsData,
  tiktokBulkChangeAdStatus, syncDataManually,
} from '../../../../../repository/TikTokDataRepository'
import dayjs from 'dayjs'
import { TikTokAccountsAtom } from '../../../../../recoil/Data.recoil'
import AdCreativePopup from '../AdCreativePopup'
import CommentPopup from '../CommentPopup'

const TikTokAdsData = () => {
  UserInfoService()

  const token = useRecoilValue(AuthorizationTokenAtom)
  const myInfo = useRecoilValue(MyInfoAtom)
  const [tiktokAccounts, setTikTokAccounts] = useRecoilState(TikTokAccountsAtom);
  const [data, setData] = useState<TikTokAdType[]>([])
  const [filter, setFilters] = useState<TikTokDataFilterType>(
    {} as TikTokDataFilterType,
  )
  const [totalElements, setTotalElements] = useState(0)
  const [bulkStatus, setBulkStatus] = useState<AD_STATUS>('') // 일괄 ON/OFF 조정을 위한 상태 추가
  const [sortConfig, setSortConfig] = useState({
    key: 'spend',
    direction: 'asc',
  } as SortingType)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [popupUsedAdId, setPopupUsedId] = useState<string>('')
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [commentPopupData, setCommentPopupData] = useState<{adId: string, comment: string} | null>(null)

  useEffect(() => {
    const ids = tiktokAccounts?.map((it) => it.advertiserId)
    if (!_.isEmpty(token) && ids?.length > 0) {
      const campaignIds = tiktokAccounts[0].campaigns?.map((it) => it.campaignId)
      if (campaignIds?.length > 0) {
        const initFilters = {
          advertiserId: ids[0],
          campaignId: campaignIds[0],
          adGroupId: '',
          adGroupName: '',
          adId: '',
          adName: '',
          startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        } as TikTokDataFilterType
        setFilters((prev) => initFilters)
        fetchData(initFilters)
      }
    }
  }, [myInfo, tiktokAccounts])

  // 코멘트 수정 버튼 클릭 시 호출되는 함수
  const handleOpenCommentPopup = (adId: string, comment: string) => {
    setCommentPopupData({ adId, comment }); // 코멘트 팝업 열기
  };

  const handleCloseCommentPopup = (type: 'EDIT' | 'CANCEL') => {
    setCommentPopupData(null);
    if(type == 'EDIT'){
      fetchData(filter)
    }
  };

  const handleSyncClick = () => {
    if(myInfo.adAccount.tier !== 'PREMIUM'){
      alert('프리미엄 티어 이상부터 사용가능 합니다.')
      return
    }

    setIsSyncing(true) // 동기화 중 상태 true
    syncDataManually(token, filter.startDate, filter.endDate)
      .then((res) => {
        if(res.success){
          alert('동기화 성공!')
          fetchTikTokAccounts(token)
            .then((res) => {
              setTikTokAccounts(res);
            })
            .catch((error) => console.error(error));
        }else{
            alert(`동기화 실패. \n${res.message}`)
        }
      })
      .catch((e) => {
        // 실패 시 처리
        console.error(e)
        alert('동기화 실패. \n관리자에게 문의하세요.')
      })
      .finally(() => {
        setIsSyncing(false) // 동기화 중 상태 false
      })
  }

  const getLatestSyncTime = () => {
    const successfulSyncs = tiktokAccounts[0]?.latestSyncHistories?.filter(
      (history) => history.status === 'SUCCESS',
    )
    if (successfulSyncs?.length > 0) {
      const latestSync = _.maxBy(successfulSyncs, (sync) =>
        dayjs(sync.updatedAt).valueOf(),
      )
      return latestSync?.updatedAt
        ? dayjs(latestSync.updatedAt).format('YYYY-MM-DD HH:mm:ss')
        : null
    }
    return null
  }

  const latestSyncTime = getLatestSyncTime()
  const isSyncSuccessful = latestSyncTime !== null

  const handleCheckboxChange = (adId: string) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(adId)
        ? prevSelected.filter((id) => id !== adId)
        : [...prevSelected, adId],
    );
  };

  // 전체 선택 체크박스 핸들러
  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]) // 모두 선택된 상태에서 다시 클릭하면 모두 해제
    } else {
      setSelectedRows(data.map((row) => row.adId)) // 전체 선택
    }
  }

  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      return [...data].sort((a, b) => {
        const key = sortConfig.key as keyof TikTokAdType // 'key'의 타입을 명확히 지정
        if (a[key] < b[key]) {
          return sortConfig.direction === 'asc' ? -1 : 1
        }
        if (a[key] > b[key]) {
          return sortConfig.direction === 'asc' ? 1 : -1
        }
        return 0
      })
    }
    return data
  }, [data, sortConfig])

  const requestSort = (key: string) => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  const convertStatusOpposite = (status: string): AD_STATUS => {
    return status === 'ENABLE' ? 'DISABLE' : 'ENABLE'
  }

  const handleAdSwitchChange = (
    adId: string,
    adName: string,
    status: AD_STATUS,
  ) => {
    const action = status === 'ENABLE' ? 'off' : 'on';
    const message = `해당 광고 ${action} 하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');

    if (memo != null) {
      setIsLoading(true);
      changeAdStatus(token, adId, convertStatusOpposite(status), memo)
        .then((res) => {
          if (res.success) {
            alert(`[AD] ${adName} ${action === 'on' ? 'ON 성공' : 'OFF 성공'}`);
            setSelectedRows([]); // 체크박스 해제
            setBulkStatus(''); // Bulk 상태 초기화
            handleSearch(filter);
          } else {
            alert(
              `[AD] ${adName} ${action === 'on' ? 'ON 실패' : 'OFF 실패'}\n 관리자 문의 부탁드립니다.`,
            );
          }
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  };

  const fetchData = (filter: TikTokDataFilterType) => {
    setIsLoading(true)
    const queryParams = [
      `advertiserId=${filter.advertiserId}`,
      filter.campaignId ? `campaignId=${filter.campaignId}` : '',
      filter.adGroupId ? `adGroupId=${filter.adGroupId}` : '',
      filter.adGroupName ? `adGroupName=${filter.adGroupName}` : '',
      filter.adGroupStatus ? `adGroupStatus=${filter.adGroupStatus}` : '',
      filter.adId ? `adId=${filter.adId}` : '',
      filter.adName ? `adName=${filter.adName}` : '',
      filter.adStatus ? `adStatus=${filter.adStatus}` : '',
      filter.goeSpend !== undefined ? `goeSpend=${filter.goeSpend}` : '',
      filter.loeSpend !== undefined ? `loeSpend=${filter.loeSpend}` : '',
      filter.goeRoas !== undefined ? `goeRoas=${filter.goeRoas}` : '',
      filter.loeRoas !== undefined ? `loeRoas=${filter.loeRoas}` : '',
      `from=${filter.startDate}`,
      `to=${filter.endDate}`,
    ]
      .filter(Boolean) // 빈 문자열을 제거합니다.
      .join('&') // '&'로 쿼리 파라미터를 연결합니다.

    fetchTikTokAdsData(token, queryParams)
      .then((res) => {
        setData(res)
        setTotalElements(res.length)
      })
      .catch((e) => {
        console.error(e.message)
        alert('데이터 조회 실패!')
      })
      .finally(() => {
        setIsLoading(false) // 로딩 끝
      })
  }

  const handleBulkStatusChange = () => {
    if (selectedRows.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }

    const action = bulkStatus === 'ENABLE' ? '활성화' : '비활성화';
    const message = `선택된 광고를 ${action} 하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');

    if (memo != null) { // memo 입력창에서 취소 버튼을 누른 경우를 제외하고 처리
      setIsLoading(true);
      const requestPayload = data
        .filter((item) => selectedRows.includes(item.adId))
        .map(
          (item) =>
            ({
              id: item.adId,
              status: bulkStatus,
              memo: memo, // memo를 각 요청에 포함
            } as TikTokStatusEditType),
        );

      tiktokBulkChangeAdStatus(token, requestPayload)
        .then((responses) => {
          const failedIds = responses
            .filter((res) => !res.success)
            .map((res) => res.id);

          if (failedIds.length === 0) {
            alert('일괄 상태 변경 성공!');
          } else {
            alert(
              `일괄 상태 변경 실패! 실패한 ID: ${failedIds.join(', ')}\n 관리자에게 문의해주세요.`,
            );
          }

          setSelectedRows([]); // 체크박스 해제
          setBulkStatus(''); // Bulk 상태 초기화
          handleSearch(filter);
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  };

  const handleSearch = (filter: TikTokDataFilterType) => {
    fetchData(filter)
  }

  const handleToggleClick = (e: React.MouseEvent) => {
    e.stopPropagation() // Row 클릭 이벤트로부터 이벤트 버블링을 막습니다.
  }

  const handleOpenPopup = (adId: string) => {
    setPopupUsedId(adId) // adId만 저장
    setIsPopupOpen(true) // 팝업 열기
  }

  const handleClosePopup = () => {
    setIsPopupOpen(false) // 팝업 닫기
  }

  if (tiktokAccounts.length === 0) {
    return <></>
  }

  return (
    <div className={S.container}>
      <h1 className={S.pageTitle}>TikTok 광고 관리</h1>
      
      <TikTokDataSearch
        tikTokAccounts={tiktokAccounts || []}
        filters={filter}
        setFilters={setFilters}
        handleSearch={handleSearch}
        searchType={'AD'}
      />

      <div className={S.dataSection}>
        <div className={S.dataInfo}>
          <span className={S.totalCount}>총 {totalElements}개</span>
          <div className={S.syncInfo}>
            <div className={S.syncStatus}>
              <span className={isSyncSuccessful ? 'syncSuccess' : 'syncError'}>
                {isSyncSuccessful ? '데이터 동기화 성공' : '데이터 Sync 실패'}
              </span>
              <div className={isSyncSuccessful ? S.greenLight : S.redLight} />
              {isSyncSuccessful && (
                <span className={S.syncTime}>Last Sync: {latestSyncTime}</span>
              )}
            </div>
            <button
              className={`${S.syncButton} ${isSyncing ? S.spinning : ''}`}
              onClick={handleSyncClick}
              disabled={isSyncing}
            >
              {isSyncing ? <FaSyncAlt className={S.syncIcon} /> : '수동 동기화'}
            </button>
          </div>
        </div>

        {totalElements > 0 && (
          <div className={S.bulkControls}>
            <div className={S.bulkControlsTitle}>일괄 작업</div>
            <div className={S.bulkActions}>
              <button
                className={`${S.statusButton} ${bulkStatus === 'ENABLE' ? S.active : ''}`}
                onClick={() => setBulkStatus('ENABLE')}
              >
                전체 ON
              </button>
              <button
                className={`${S.statusButton} ${bulkStatus === 'DISABLE' ? S.active : ''}`}
                onClick={() => setBulkStatus('DISABLE')}
              >
                전체 OFF
              </button>
              <button
                className={S.applyButton}
                onClick={handleBulkStatusChange}
                disabled={!bulkStatus || selectedRows.length === 0}
              >
                적용
              </button>
            </div>
          </div>
        )}

        {isLoading ? (
          <div className={S.loadingWrapper}>
            <div className={S.loader} />
            <p>데이터를 불러오는 중입니다...</p>
          </div>
        ) : totalElements === 0 ? (
          <p className={S.noData}>데이터가 없습니다.</p>
        ) : (
          <div className={S.tableWrapper}>
            <table className={S.table}>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectedRows.length === data.length}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th>상태</th>
                  <th>캠페인 명</th>
                  <th>광고 세트명</th>
                  <th>광고명</th>
                  <th>소재 및 Insights</th>
                  <th>메모</th>
                  <th onClick={() => requestSort('spend')}>
                    지출{' '}
                    {sortConfig.key === 'spend'
                      ? sortConfig.direction === 'asc'
                        ? '▲'
                        : '▼'
                      : null}
                  </th>
                  <th>매출</th>
                  <th>ROAS</th>
                  <th>노출</th>
                  <th>CTR</th>
                  <th>클릭 수</th>
                  <th>CPC</th>
                  <th>CPM</th>
                  <th>구매당 매출</th>
                  <th>전환율</th>
                </tr>
              </thead>
              <tbody>
                {sortedData?.map((row, rowIndex) => {
                  return (
                    <React.Fragment
                      key={`${row.campaignId}-${row.adGroupId}-${row.adId}`}
                    >
                      <tr>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(row.adId)}
                            onChange={() => handleCheckboxChange(row.adId)}
                          />
                        </td>
                        <td>
                          <div className={S.switchWrapper}>
                            <Switch
                              checked={row.status === 'ENABLE'}
                              onChange={() =>
                                handleAdSwitchChange(row.adId, row.name, row.status)
                              }
                              onColor="#2e7d32"
                              offColor="#e0e0e0"
                              onHandleColor="#ffffff"
                              offHandleColor="#ffffff"
                              handleDiameter={20}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={24}
                              width={48}
                            />
                          </div>
                        </td>
                        <td>{row.campaignName}</td>
                        <td>{row.adGroupName}</td>
                        <td>{row.name}</td>
                        <td>
                          <button
                            className={S.popupBtn}
                            onClick={() => handleOpenPopup(row.adId)}
                          >
                            자세히 보기
                          </button>
                        </td>
                        <td>
                          <div className={S.commentWrapper}>
                            <button
                              className={S.editCommentButton}
                              onClick={() =>
                                handleOpenCommentPopup(row.adId, row.memo)
                              }
                            >
                              메모 확인
                            </button>
                          </div>
                        </td>
                        <td>{row.spend?.toLocaleString()}</td>
                        <td>{row.revenue?.toLocaleString()}</td>
                        <td>{row.roas}</td>
                        <td>{row.impressions?.toLocaleString()}</td>
                        <td>{row.ctr?.toLocaleString()}</td>
                        <td>{row.clicks?.toLocaleString()}</td>
                        <td>{row.cpc?.toLocaleString()}</td>
                        <td>{row.cpm?.toLocaleString()}</td>
                        <td>{row.revenuePerPurchase?.toLocaleString()}</td>
                        <td>{row.purchaseRatio}</td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {commentPopupData && (
        <CommentPopup
          adId={commentPopupData.adId}
          initialComment={commentPopupData.comment}
          onClose={handleCloseCommentPopup}
        />
      )}

      {isPopupOpen && !_.isEmpty(popupUsedAdId) && (
        <AdCreativePopup
          adId={popupUsedAdId}
          from={dayjs(filter?.endDate).subtract(6, 'day').format('YYYY-MM-DD')}
          to={filter?.endDate}
          onClose={handleClosePopup}
        />
      )}
    </div>
  )
}

export default TikTokAdsData
