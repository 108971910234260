import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import S from './TikTokDataSearch.module.scss'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'
import { AuthorizationTokenAtom } from '../../../../../recoil/Authorization.recoil'
import { useRecoilValue } from 'recoil'
import { TikTokAdAccountType } from '../../../../../types/LoginTypes'
import { TikTokDataFilterType } from '../../../../../types/TikTokDataTypes'

interface TikTokIdNameType {
  id: string
  name: string
}

interface TikTokDataSearchProps {
  tikTokAccounts: TikTokAdAccountType[];
  handleSearch: (filter: TikTokDataFilterType) => void;
  filters: TikTokDataFilterType;
  setFilters: Dispatch<SetStateAction<TikTokDataFilterType>>;
  searchType: 'AD-GROUP' | 'AD'
}

interface AlertProps {
  message: string;
  type: 'warning' | 'info';
}

const TikTokDataSearch = (props: TikTokDataSearchProps) => {
  const token = useRecoilValue(AuthorizationTokenAtom)
  const { tikTokAccounts, setFilters, filters, handleSearch, searchType } = props
  const [accounts, setAccounts] = useState<TikTokIdNameType[]>([]) // 계정 ID 목록
  const [campaigns, setCampaigns] = useState<TikTokIdNameType[]>([])
  const [startDate, setStartDate] = useState<Date>(dayjs().subtract(1, 'day').toDate())
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate())

  // 필수 필드의 유효성 상태를 관리합니다.
  const [isAccountIdValid, setIsAccountIdValid] = useState<boolean>(true)
  const [isCampaignIdValid, setIsCampaignIdValid] = useState<boolean>(true)
  const [isStartDateValid, setIsStartDateValid] = useState<boolean>(true)
  const [isEndDateValid, setIsEndDateValid] = useState<boolean>(true)
  const [isSpendValid, setIsSpendValid] = useState<boolean>(true);
  const [isRoasValid, setIsRoasValid] = useState<boolean>(true);

  const [selectedSpendCondition, setSelectedSpendCondition] = useState<string | undefined>()
  const [selectedRoasCondition, setSelectedRoasCondition] = useState<string | undefined>()
  const [selectedAdGroupStatus, setSelectedAdGroupStatus] = useState<string | undefined>();
  const [selectedAdStatus, setSelectedAdStatus] = useState<string | undefined>();

  const [alert, setAlert] = useState<AlertProps | null>(null);

  useEffect(() => {
    setAccounts(tikTokAccounts.map((account) =>
      ({ id: account.advertiserId, name: account.advertiserName })))
    setCampaigns(tikTokAccounts[0].campaigns.map((campaign) =>
      ({ id: campaign.campaignId, name: campaign.campaignName })))
    setFilters(prev => ({
      ...prev,
      adGroupId: '',
      adGroupName: '',
      adId: '',
      adName: '',
      startDate: dayjs(startDate).subtract(1).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    }))
  }, [])

  useEffect(() => {
    if (filters.advertiserId) {
      const selectedAccount = tikTokAccounts.find(it => it.advertiserId === filters.advertiserId)
      if (selectedAccount !== undefined) {
        setCampaigns(selectedAccount?.campaigns.map((campaign) =>
          ({ id: campaign.campaignId, name: campaign.campaignName })))

        setFilters(prev => ({
          ...prev,
          adGroupId: '',
          adGroupName: '',
          adId: '',
          adName: '',
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        }))
      }
    }
  }, [filters.advertiserId])

  useEffect(() => {
    setStartDate(dayjs(filters.startDate).toDate())
    setEndDate(dayjs(filters.endDate).toDate())
  }, [filters.startDate, filters.endDate])

  const validateFields = () => {
    const isValidAccountId = !!filters.advertiserId;
    const isValidCampaignId = !!filters.campaignId;
    const isValidStartDate = !!filters.startDate;
    const isValidEndDate = !!filters.endDate;

    // Spend와 ROAS의 유효성 검사
    const isValidSpend = !!(selectedSpendCondition || (!selectedSpendCondition && filters.goeSpend === undefined && filters.loeSpend === undefined));
    const isValidRoas = !!(selectedRoasCondition || (!selectedRoasCondition && filters.goeRoas === undefined && filters.loeRoas === undefined));

    setIsAccountIdValid(isValidAccountId);
    setIsCampaignIdValid(isValidCampaignId);
    setIsStartDateValid(isValidStartDate);
    setIsEndDateValid(isValidEndDate);
    setIsSpendValid(isValidSpend);
    setIsRoasValid(isValidRoas);

    return isValidAccountId && isValidCampaignId && isValidStartDate && isValidEndDate && isValidSpend && isValidRoas;
  };


  const handleSearchClick = () => {
    if (validateFields()) {
      const startDate = dayjs(filters.startDate)
      const endDate = dayjs(filters.endDate)
      const diffDays = endDate.diff(startDate, 'day')

      if (diffDays > 7) {
        showAlert('7일 이상의 기간은 검색할 수 없습니다. 날짜를 다시 선택해주세요.', 'warning')
        return
      }

      handleSearch(filters)
    }
  }

  const handleAdGroupStatusChange = (status: string) => {
    if (selectedAdGroupStatus === status) {
      setSelectedAdGroupStatus(undefined);
      setFilters(prev => ({ ...prev, setStatus: undefined }));
    } else {
      setSelectedAdGroupStatus(status);
      setFilters(prev => ({ ...prev, setStatus: status }));
    }
  };

  const handleAdStatusChange = (status: string) => {
    if (selectedAdStatus === status) {
      setSelectedAdStatus(undefined);
      setFilters(prev => ({ ...prev, adStatus: undefined }));
    } else {
      setSelectedAdStatus(status);
      setFilters(prev => ({ ...prev, adStatus: status }));
    }
  };

  const handleSpendConditionChange = (condition: string) => {
    if (selectedSpendCondition === condition) {
      setSelectedSpendCondition(undefined);
      setFilters(prev => ({ ...prev, goeSpend: undefined, loeSpend: undefined }));
    } else {
      setSelectedSpendCondition(condition);
      // 기존 필터의 값에 따라 상태 업데이트
      if (condition === '>=') {
        setFilters(prev => ({ ...prev, loeSpend: undefined }));
      } else if (condition === '<=') {
        setFilters(prev => ({ ...prev, goeSpend: undefined }));
      }
    }
  };

  const handleRoasConditionChange = (condition: string) => {
    if (selectedRoasCondition === condition) {
      setSelectedRoasCondition(undefined);
      setFilters(prev => ({ ...prev, goeRoas: undefined, loeRoas: undefined }));
    } else {
      setSelectedRoasCondition(condition);
      // 기존 필터의 값에 따라 상태 업데이트
      if (condition === '>=') {
        setFilters(prev => ({ ...prev, loeRoas: undefined }));
      } else if (condition === '<=') {
        setFilters(prev => ({ ...prev, goeRoas: undefined }));
      }
    }
  };

  const handleSpendChange = (value: string) => {
    const numericValue = parseFloat(value);

    if (!selectedSpendCondition) {
      // 수식이 선택되지 않았을 때 경고 메시지 표시
      setIsSpendValid(false);
      // 1초 후에 경고 메시지 자동으로 사라지게 설정
      setTimeout(() => {
        setIsSpendValid(true);
      }, 1000);
    } else if (selectedSpendCondition === '>=') {
      setFilters(prev => ({ ...prev, goeSpend: isNaN(numericValue) ? undefined : numericValue }));
      setIsSpendValid(true);
    } else if (selectedSpendCondition === '<=') {
      setFilters(prev => ({ ...prev, loeSpend: isNaN(numericValue) ? undefined : numericValue }));
      setIsSpendValid(true);
    }
  };

  const handleRoasChange = (value: string) => {
    const numericValue = parseFloat(value);

    if (!selectedRoasCondition) {
      // 수식이 선택되지 않았을 때 경고 메시지 표시
      setIsRoasValid(false);
      // 1초 후에 경고 메시지 자동으로 사라지게 설정
      setTimeout(() => {
        setIsRoasValid(true);
      }, 1000);
    } else if (selectedRoasCondition === '>=') {
      setFilters(prev => ({ ...prev, goeRoas: isNaN(numericValue) ? undefined : numericValue }));
      setIsRoasValid(true);
    } else if (selectedRoasCondition === '<=') {
      setFilters(prev => ({ ...prev, loeRoas: isNaN(numericValue) ? undefined : numericValue }));
      setIsRoasValid(true);
    }
  };

  // 알림 표시 함수 추가
  const showAlert = (message: string, type: 'warning' | 'info' = 'info') => {
    setAlert({ message, type });
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  return (
    <>
      {alert && (
        <div className={`${S.alert} ${S[alert.type]}`}>
          <span className={S.icon}>
            {alert.type === 'warning' ? '⚠️' : 'ℹ️'}
          </span>
          <span className={S.message}>{alert.message}</span>
        </div>
      )}
      
      <div className={S.searchContainer}>
        <h2>필터</h2>
        
        {/* 기본 정보 섹션 */}
        <div className={S.searchGroup}>
          <div className={S.flexContainer}>
            <div className={`${S.searchItem} ${S.largeSelect} ${!isAccountIdValid && S.invalidField}`}>
              <label>계정</label>
              <select
                value={filters.advertiserId}
                onChange={(e) => setFilters(prev => ({ ...prev, advertiserId: e.target.value }))}
              >
                {accounts.map((account) => (
                  <option key={account.id} value={account.id}>{account.name}</option>
                ))}
              </select>
              {!isAccountIdValid && <small className={S.errorText}>계정을 선택해주세요</small>}
            </div>
            <div className={`${S.searchItem} ${S.largeSelect} ${!isCampaignIdValid && S.invalidField}`}>
              <label>캠페인</label>
              <select
                value={filters?.campaignId}
                onChange={(e) => setFilters(prev => ({ ...prev, campaignId: e.target.value }))}
              >
                {campaigns.map((campaign) => (
                  <option key={campaign.id} value={campaign.id}>{campaign.name}</option>
                ))}
              </select>
              {!isCampaignIdValid && <small className={S.errorText}>캠페인을 선택해주세요</small>}
            </div>
          </div>
        </div>

        {/* 광고 그룹 필터 섹션 */}
        <div className={S.searchGroup}>
          <div className={S.flexContainer}>
            <div className={`${S.searchItem} ${S.smallSelect}`}>
              <label>광고 그룹 상태</label>
              <div className={S.inlineGroup}>
                <button
                  className={`${S.conditionButton} ${selectedAdGroupStatus === 'ENABLE' ? S.active : ''}`}
                  onClick={() => handleAdGroupStatusChange('ENABLE')}
                >
                  ON
                </button>
                <button
                  className={`${S.conditionButton} ${selectedAdGroupStatus === 'DISABLE' ? S.active : ''}`}
                  onClick={() => handleAdGroupStatusChange('DISABLE')}
                >
                  OFF
                </button>
              </div>
            </div>
            <div className={`${S.searchItem} ${S.searchInput}`}>
              <label>광고 그룹명</label>
              <input
                type="text"
                value={filters?.adGroupName}
                onChange={(e) => setFilters(prev => ({ ...prev, adGroupName: e.target.value }))}
                placeholder="광고 그룹명을 입력하세요"
              />
            </div>
            <div className={S.searchItem}>
              <label>지출 금액</label>
              <div className={S.inlineGroup}>
                <button
                  className={`${S.conditionButton} ${selectedSpendCondition === '>=' ? S.active : ''}`}
                  onClick={() => handleSpendConditionChange('>=')}
                >
                  ≥
                </button>
                <button
                  className={`${S.conditionButton} ${selectedSpendCondition === '<=' ? S.active : ''}`}
                  onClick={() => handleSpendConditionChange('<=')}
                >
                  ≤
                </button>
                <input
                  type="number"
                  value={filters.goeSpend !== undefined ? filters.goeSpend : filters.loeSpend !== undefined ? filters.loeSpend : ''}
                  onChange={(e) => handleSpendChange(e.target.value)}
                  className={`${S.searchInput} ${!isSpendValid && S.invalidField}`}
                  placeholder="금액 입력"
                />
              </div>
              {!isSpendValid && !selectedSpendCondition && 
                <small className={S.errorText}>조건을 먼저 선택해주세요</small>}
            </div>
          </div>
        </div>

        {/* 광고 필터 섹션 */}
        {searchType === 'AD' && (
          <div className={S.searchGroup}>
            <div className={S.flexContainer}>
              <div className={`${S.searchItem} ${S.smallSelect}`}>
                <label>광고 상태</label>
                <div className={S.inlineGroup}>
                  <button
                    className={`${S.conditionButton} ${selectedAdStatus === 'ACTIVE' ? S.active : ''}`}
                    onClick={() => handleAdStatusChange('ACTIVE')}
                  >
                    ON
                  </button>
                  <button
                    className={`${S.conditionButton} ${selectedAdStatus === 'PAUSED' ? S.active : ''}`}
                    onClick={() => handleAdStatusChange('PAUSED')}
                  >
                    OFF
                  </button>
                </div>
              </div>
              <div className={`${S.searchItem} ${S.searchInput}`}>
                <label>광고명</label>
                <input
                  type="text"
                  value={filters?.adName}
                  onChange={(e) => setFilters(prev => ({ ...prev, adName: e.target.value }))}
                  placeholder="광고명을 입력하세요"
                />
              </div>
              <div className={S.searchItem}>
                <label>ROAS</label>
                <div className={S.inlineGroup}>
                  <button
                    className={`${S.conditionButton} ${selectedRoasCondition === '>=' ? S.active : ''}`}
                    onClick={() => handleRoasConditionChange('>=')}
                  >
                    ≥
                  </button>
                  <button
                    className={`${S.conditionButton} ${selectedRoasCondition === '<=' ? S.active : ''}`}
                    onClick={() => handleRoasConditionChange('<=')}
                  >
                    ≤
                  </button>
                  <input
                    type="number"
                    value={filters.goeRoas !== undefined ? filters.goeRoas : filters.loeRoas !== undefined ? filters.loeRoas : ''}
                    onChange={(e) => handleRoasChange(e.target.value)}
                    className={`${S.searchInput} ${!isRoasValid && S.invalidField}`}
                    placeholder="ROAS 입력"
                  />
                </div>
                {!isRoasValid && !selectedRoasCondition && 
                  <small className={S.errorText}>조건을 먼저 선택해주세요</small>}
              </div>
            </div>
          </div>
        )}

        {/* 날짜 선택 및 검색 버튼 */}
        <div className={S.searchFooter}>
          <div className={S.datePicker}>
            <div className={S.datePickerItem}>
              <label>시작일</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => date && setFilters(prev => ({
                  ...prev,
                  startDate: dayjs(date).format('YYYY-MM-DD'),
                }))}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={dayjs(endDate).subtract(1, 'day').toDate()}
                dateFormat="yyyy-MM-dd"
                className={S.customDatePicker}
                placeholderText="시작일 선택"
                popperPlacement="bottom-start"
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div className={S.datePickerHeader}>
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      ◀
                    </button>
                    <span>{dayjs(date).format('YYYY년 MM월')}</span>
                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      ▶
                    </button>
                  </div>
                )}
              />
            </div>
            <span className={S.datePickerSeparator}>~</span>
            <div className={S.datePickerItem}>
              <label>종료일</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => date && setFilters(prev => ({ ...prev, endDate: dayjs(date).format('YYYY-MM-DD') }))}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={dayjs(startDate).add(7, 'day').toDate()}
                dateFormat="yyyy-MM-dd"
                className={S.customDatePicker}
                placeholderText="종료일 선택"
                popperPlacement="bottom-start"
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div className={S.datePickerHeader}>
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      ◀
                    </button>
                    <span>{dayjs(date).format('YYYY년 MM월')}</span>
                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      ▶
                    </button>
                  </div>
                )}
              />
            </div>
            <button className={S.searchBtn} onClick={handleSearchClick}>
              검색
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TikTokDataSearch